import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import headerBarsExpanded from '@images/header/header-bars-expanded.png';
import headerElementCentre from '@images/header/element-placement-centre-1.png';
import headerElementCentre2 from '@images/header/element-placement-centre-2.png';
import headerElementCentre3 from '@images/header/element-placement-centre-3.png';
import headerGlobal from '@images/header/bar-global-nav.png';
import headerMainNav from '@images/header/bar-main-nav.png';
import headerSearch from '@images/header/bar-search.png';
import headerTabs from '@images/header/bar-tabs.png';
import headerElementPlacement from '@images/header/element-placement-left-1.png';
import headerElementPlacement2 from '@images/header/element-placement-right-1.png';
import headerSkipLink from '@images/header/accessibility-skip-link.png';
import headerNativeLeftIcon from '@images/header/LeftIcon.png';
import headerNativeRightIcon from '@images/header/RightIcon.png';
import headerNativeTitle from '@images/header/Title.png';
import headerNativeLeftAction from '@images/header/LeftAction.png';
import headerNativeRightAction from '@images/header/RightAction.png';
import headerNativeReadingOrder from '@images/header/ReadingOrder.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
    
    <ul>
        <li><a className="ln-c-link" href="#how-to-use-the-header">How to use the header</a></li>
        <li><a className="ln-c-link" href="#navigation-bars-for-web">Navigation bars for web</a></li>
        <li><a className="ln-c-link" href="#elements-for-native">Elements for native</a></li>
        <li><a className="ln-c-link" href="#copy-guidelines">Copy guidelines</a></li>
    </ul>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-the-header",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-the-header",
        "aria-label": "how to use the header permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use the header`}</h2>
    <p>{`The header makes the most important parts of your experience easy for users to find.`}</p>
    <ul>
      <li parentName="ul">{`Always place it at the top of the page`}</li>
      <li parentName="ul">{`Use the ARIA landmark role to help screen reader users find and understand the header and header navigation elements`}</li>
      <li parentName="ul">{`The header component supports text resize, but you should still test with large text sizes in the browser`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "navigation-bars-for-web",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#navigation-bars-for-web",
        "aria-label": "navigation bars for web permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Navigation bars for web`}</h2>
    <p>{`The header component offers navigation bars that you can adapt to suit lots of scenarios. You can use these navigation bars together or independently, depending on your user’s needs.`}</p>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#each-bar-is-optional">
        Each bar is optional
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#global-nav-bar">
        Global nav bar
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#main-nav-bar">
        Main nav bar
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#search-bar">
        Search bar
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#tab-bar">
        Tab bar
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#skip-link">
        Skip link
      </a>
    </li>
  </ul>
    </TableOfContents>
    <img src={headerBarsExpanded} alt="decomposition of header showing it's construction using a global, main, search and tab bars" className="ln-u-push-bottom" />
    <h3 id="each-bar-is-optional">Each bar is optional</h3>
    <p>{`Remember that you don’t need to use every navigation bar. Think about the particular use case for each one, and if they suit your user’s requirements.`}</p>
    <h3 id="global-nav-bar">Global nav bar</h3>
The global nav bar enables quick access to other Sainsbury’s brands. The default
height size of the global nav bar is 36px.
    <img src={headerGlobal} alt="small header bar with text links" className="ln-u-push-bottom" />
    <h3 id="main-nav-bar">Main nav bar</h3>
    <p>{`The main nav bar houses the most relevant Sainsbury’s Group logo for the context, and the top-level navigation items. The default height size of the main nav bar is 80px. When scrolling the sticky header is 64px.`}</p>
    <img src={headerMainNav} alt="large header bar with Sainsbury's logo" className="ln-u-push-bottom" />
    <h3 id="search-bar">Search bar</h3>
    <p>{`The search bar enables a user to search, so they can find content quickly and easily. The default height size of the search bar is 56px.`}</p>
    <img src={headerSearch} alt="search field with padding on a small header bar" className="ln-u-push-bottom" />
    <h3 id="tab-bar">Tab bar</h3>
The tab bar allows a large number of level 1 navigation items to be displayed on
smaller screen sizes. The default height size of the tab bar is 48px.
    <img src={headerTabs} alt="nav items on a small header bar" className="ln-u-push-bottom" />
    <h3 id="skip-link">Skip link</h3>
The skip link allows keyboard only and screen reader users to skip over repetitive
content like menus. Otherwise, users have to tab through these on every page.{' '}
    <p>{`The skip link element will only be visible when it receives focus. It should be the first focusable element on the page.`}</p>
    <img src={headerSkipLink} alt="header showing an example of an in focus skip link" className="ln-u-push-bottom" />
    <hr></hr>
    <h2 {...{
      "id": "aligning-elements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#aligning-elements",
        "aria-label": "aligning elements permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Aligning elements`}</h2>
    <p>{`Elements can be aligned to the centre, left or right within the nav bars.`}</p>
    <h3>{`Centre aligned`}</h3>
    <p>{`When there is only one nav item it should be centre aligned.`}</p>
    <figure>
  <img src={headerElementCentre} alt="text centered on header bar" />
  <figcaption className="ln-u-caption">
    Example of an inline text link centred on a Global Bar
  </figcaption>
    </figure>
    <figure>
  <img src={headerElementCentre2} alt="logo centered on header" />
  <figcaption className="ln-u-caption">
    Example of a home logo centred on a Main Nav Bar
  </figcaption>
    </figure>
    <figure>
  <img src={headerElementCentre3} alt="tab bar with centered content" />
  <figcaption className="ln-u-caption">
    Example of a home logo and nav items left aligned in a Main Nav Bar
  </figcaption>
    </figure>
    <h3>{`Left and right aligned`}</h3>
    <p>{`You can create two groups of related items and align them to the left and right.`}</p>
    <figure>
  <img src={headerElementPlacement} alt="header bar showing a text link aligned to the left of the bar" />
  <figcaption className="ln-u-caption">
    Example of a home logo and nav items left aligned in a Main Nav Bar
  </figcaption>
    </figure>
    <figure>
  <img src={headerElementPlacement2} alt="header bar showing icons and button aligned to the right of the bar" />
  <figcaption className="ln-u-caption">
    Example of a search field and nav items right aligned in a Main Nav Bar
  </figcaption>
    </figure>
    <hr></hr>
    <h2 {...{
      "id": "elements-for-native",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#elements-for-native",
        "aria-label": "elements for native permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Elements for native`}</h2>
    <p>{`There are a number of different elements that make up our native header. You can use these elements together or independently, depending on your user’s needs.`}</p>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#left-icon">
        Left icon
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#title">
        Title
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#right-icon">
        Right icon
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#left-action">
        Left action
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#right-action">
        Right action
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="left-icon">Left icon</h3>
    <p>Use the left icon to show a back arrow when the screen is part of a journey. Do not
include a left icon if the screen is not part of a journey.
      <img src={headerNativeLeftIcon} alt="small header with left aligned icon" className="ln-u-push-top" /></p>
    <h3 id="title">Title</h3>
    <p>Write a title that describes what the user is seeing. Aim to keep titles short and descriptive, 1 or 2 words should do the trick.
      <img src={headerNativeTitle} alt="small header with title text" className="ln-u-push-top" /></p>
    <h3 id="right-icon">Right icon</h3>
    <p>Use the right icon to show actions that make sense for the screen the user is seeing.
For example, the right icon could be used for actions like:
      <ul className="ln-u-push-top">
  <li>See more</li>
  <li>Share</li>
  <li>Close (do not use if also using a back arrow as the left icon)</li>
      </ul>
      <img src={headerNativeRightIcon} alt="small header with right aligned icon" /></p>
    <h3 id="left-action">Left action</h3>
    <p>{`Use the left action when an icon doesn’t work for what you need to communicate. Choose an action that’s short, clear and intuitive to users.`}</p>
    <img src={headerNativeLeftAction} alt="small header with left aligned action" className="ln-u-push-bottom" />
    <h3 id="right-action">Right action</h3>
    <p>{`Use the right action when an icon doesn’t work for what you need to communicate. Choose an action that’s short, clear and intuitive to users.`}</p>
    <img src={headerNativeRightAction} alt="small header with right aligned action" className="ln-u-push-bottom" />
    <h3 id="naming-elements">Naming elements</h3>
    <p>{`Choose a name that describes the icon or action as accurately as possible. Make sure that both design and development know the name of each icon and action.`}</p>
    <p>{`A screen reader will read the elements from left to right. It will read each element like this:`}</p>
    <ul>
      <li parentName="ul">{`Left icon = “(Accessible name) button”`}</li>
      <li parentName="ul">{`Title = “(Accessible name) heading”`}</li>
      <li parentName="ul">{`Right icon = “(Accessible name) action”`}</li>
      <li parentName="ul">{`Left action = “(Accessible name) action”`}</li>
      <li parentName="ul">{`Right action = “(Accessible name) button”`}</li>
    </ul>
    <img src={headerNativeReadingOrder} alt="image showing the reading order of the elements" className="ln-u-push-top" />
    <hr></hr>
    <h2 {...{
      "id": "copy-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copy-guidelines",
        "aria-label": "copy guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Copy guidelines`}</h2>
    <p>{`Labels in the header should start with a capital letter and be in sentence case, unless they are a brand name.`}</p>
    <p>{`You can use an ampersand (&) in place of the word ‘and’ to reduce characters.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      